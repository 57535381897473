import React, { PureComponent } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import EventModel from '../../../../utils/tracking/models';
import { trackGAEvent } from '../../../../client/utils/third_party_trackers/ga';

class CategoryLink extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
    this.handleLinkTracking = this.handleLinkTracking.bind(this);
  }

  getCategoryData(topic) {
    let className = '';
    const active = topic.url === get(this.props, 'activeCategory');

    if (active) {
      className = 'nav__active';
    }

    return { className, active };
  }

  hasMobileTitle(topic) {
    const { mobileTitle } = topic;
    return mobileTitle;
  }

  handleLinkTracking(topic, index) {
    const { title } = topic;

    // [DataLayer] trigger nav item click event
    const clickEvent = new EventModel(
      'Top Navigation Click',
      `nav${title}`,
      'siteNavigation',
      `navigation ${title}`,
      index + 1
    );
    this.props.trackClick(clickEvent.getTrackingObject());

    trackGAEvent({
      eventCategory: 'Interaction',
      eventAction: 'Top Nav Category',
      eventLabel: title
    });
  }

  render() {
    const { topic, index, setInActiveState } = this.props;
    const { url, title, external, eyebrow } = topic;
    const data = this.getCategoryData(topic);
    const hasMobileTitle = this.hasMobileTitle(topic);
    let mobileTitle;

    const attrs = {
      className: data.className,
      title
    };

    if (hasMobileTitle !== undefined) {
      mobileTitle = hasMobileTitle;
    }

    if (data.active) {
      return (
        <React.Fragment>
          <span {...attrs}>{title}</span>
        </React.Fragment>
      );
    }

    if (external) {
      attrs.href = `${url}`;
      attrs.target = '_blank';

      return (
        <a
          {...attrs}
          onClick={() => {
            setInActiveState();
            this.handleLinkTracking(topic, index);
          }}
        >
          {eyebrow && <span className="masthead-eyebrow">{eyebrow}</span>}
          {title}
        </a>
      );
    }

    attrs.to = url;

    if (mobileTitle) {
      return (
        <Link
          {...attrs}
          onClick={() => {
            setInActiveState();
            this.handleLinkTracking(topic, index);
          }}
        >
          <span className="hideOnSmall">{title}</span>
          <span className="showOnSmall">{mobileTitle}</span>
        </Link>
      );
    }
    return (
      <>
        <a
          {...attrs}
          onClick={() => {
            setInActiveState();
            this.handleLinkTracking(topic, index);
          }}
          href={`https://www.fastcompany.com${topic?.url}`}
        >
          {title}
        </a>
      </>
    );
  }
}

CategoryLink.defaultProps = {};

CategoryLink.propTypes = {
  topic: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    external: PropTypes.bool
  }).isRequired,
  trackClick: PropTypes.func.isRequired,
  setInActiveState: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

export default CategoryLink;
